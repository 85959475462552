import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Typist from "react-typist"
import "../styles/cursor.scss"
import styles from "../styles/index.scss"
import InfiTypist from "../components/infiTypist.js"

var notes = [
  "1%.",
  "mais où va le monde ?",
  "я держусь.",
  "what a way to start anew, to shed your skin and find the old you",
  "sometimes you're the hammer, sometimes you're the nail",
  "תֹ֙הוּ֙ וָבֹ֔הוּ.",
  "when the world is sick, can't no one be well?",
]

var randomNote = notes[Math.floor(Math.random() * notes.length)]
var lengthNote = randomNote.length

export default function Home() {
  const [count, setCount] = useState(1);

  return (
    <div>
      <Helmet>
        <title>~</title>
      </Helmet>

      <div className="body">
            <div className="type">
              <InfiTypist
                words={notes}
              />
            </div>

            <div className="links">
              <a href={'https://www.reddit.com/u/b-new'}>▼▲▼</a>
            </div>
      </div>
    </div>
  )
}
